import './application.css';

import { createApp } from 'vue/dist/vue.esm-bundler';
import { createInertiaApp, router } from '@inertiajs/vue3';
import { createPinia } from 'pinia';
const pinia = createPinia();

import '@/mixins/filters';
import store from '@/store/index';
import Notifications from 'vue3-vt-notifications';
import Interceptors from '@/helpers/response_handler';
import emitter from 'tiny-emitter/instance';
import masonry from 'vue-next-masonry';
import HighchartsVue from 'highcharts-vue';

import TheTIEDesignSystem from '@the-tie/the_tie_design_system';
import '@the-tie/the_tie_design_system/dist/style.css';

import NftLayout from '@/components/nft/NftLayout.vue';
import AdminIndex from '@/components/admin/AdminIndex.vue';
import AppLayout from '@/components/AppLayout.vue';
import NoDataFound from '@/components/NoDataFound.vue';
import CoinIcon from '@/components/CoinIcon.vue';
import GlobalSearchV2 from '@/components/GlobalSearchV2.vue';
import ModalConfirmation from '@/components/modal/ModalConfirmation.vue';

document.addEventListener('DOMContentLoaded', () => {
  document.getElementById('app').click();
  // INITIALIZE VUE INSTANCE
  createInertiaApp({
    progress: {
      color: '#3b82f6'
    },
    resolve: name => {
      const pages = import.meta.glob('../components/**/*.vue');
      const page = pages[`../components/${name}.vue`]();
      page.then(module => {
        if (name.startsWith('nft/')) module.default.layout = [AppLayout, NftLayout];
        else if (name.startsWith('admin/') && name != 'admin/AdminIndex')
          module.default.layout = [AppLayout, AdminIndex];
        else module.default.layout = module.default.layout || AppLayout;
      });
      return page;
    },
    chunkUrl: name => {
      return `${process.env.CLOUDFRONT_URL}/${name}.js`;
    },
    setup({ el, App, props, plugin }) {
      const app = createApp(App, props);
      app.use(store);
      app.use(pinia);

      //GLOBAL EVENT BUS FOR OPTIONS API COMPONENTS -- REMOVE AFTER MIGRATING TO COMPOSITION
      app.config.globalProperties.$eventHub = {
        $on: (...args) => emitter.on(...args),
        $once: (...args) => emitter.once(...args),
        $off: (...args) => emitter.off(...args),
        $emit: (...args) => emitter.emit(...args)
      };

      app.use(Notifications);
      app.use(Interceptors);
      app.use(masonry);
      app.use(TheTIEDesignSystem);
      app.use(HighchartsVue);
      app.use(plugin);

      //GLOBALLY REQUIRED COMPONENTS
      app.component('NoDataFound', NoDataFound);
      app.component('ModalConfirmation', ModalConfirmation);
      app.component('CoinIcon', CoinIcon);
      app.component('GlobalSearchV2', GlobalSearchV2);

      router.on('error', event => {
        let exception = event.detail?.exception;
        if (exception?.message === 'Network Error') {
          window.location = exception?.config?.url;
        }
      });
      app.mount(el);
    }
  });
});
